import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQueries, useQuery } from 'react-query';
import { Alert, Box, Fade, Menu, MenuItem, Typography, capitalize } from '@mui/material';
import Grid from '@mui/material/Grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import PageHeader from 'components/Common/PageHeader';
import TabComponent, { TabContext, Tabs } from 'components/Common/TabComponent';
import Toast, { ToastProps, ToastType } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import { PatientCtx } from 'pages/Dashboard/constants';
import { Logger } from 'utils/logger';
import {
  allergiesUrls,
  backgroundsUrls,
  diagnosisUrls,
  familyHistoryUrls,
  healthRecordRefreshUrls,
  hospitalizationsUrls,
  labsUrls,
  lifestyleUrls,
  medicationsUrls,
  patientProviderUrls,
  patientUrls,
  providers,
  symptomsUrls,
  trackingUrls,
  whoIam,
} from 'utils/apiUrls';
import { downloadUrl } from 'utils/service';
import {
  fetchPatientExportData,
  fetchWhoIamCategories,
} from 'pages/Dashboard/services/whoiam.services';
import { fetchProvidersAssociationsList } from 'pages/Dashboard/services/provider.services';
import { Mood, MoodV2 } from 'pages/Dashboard/types/moods.types';
import {
  fetchDiagnosisListCombined,
} from 'pages/Dashboard/services/diagnosis.services';
import {
  fetchAllMedicationList,
  fetchMedicationChartHistory,
} from 'pages/Dashboard/services/medications.services';
import { fetchPatientDetails, fetchPatientHealthRecordStatus } from 'pages/Dashboard/services/patient.services';
import fetchFamilyHistoryList from 'pages/Dashboard/services/familyhistory.services';
import {
  fetchCurrentSymptomList,
  fetchSymptomList,
} from 'pages/Dashboard/services/symptoms.services';
import fetchAllergyList from 'pages/Dashboard/services/allergies.services';
import fetchHospitalizationList from 'pages/Dashboard/services/hospitalizations.services';
import { fetchLabList } from 'pages/Dashboard/services/labs.services';
import { fetchLifeStyleList } from 'pages/Dashboard/services/lifestyle.services';
import fetchBackgroundList from 'pages/Dashboard/services/backgrounds.services';
import {
  fetchPatientPrimaryCareProviderList,
  fetchPatientMentalHealthProviderList,
  fetchPatientCurrentPrimaryCareProviderList,
  fetchAllPatientProvidersList,
} from 'pages/Dashboard/services/patientprovider.services';
import {
  defaultMoodTracking,
  defaultWaypointTracking,
  TimeFrameOptions,
} from 'pages/Dashboard/utils/trackingUtils';
import { GoogleDate, googleDateToDateString } from 'utils/dateUtils';
import PatientTracking from 'pages/Dashboard/PatientTracking';
import PatientProfile from 'pages/Dashboard/PatientProfile';
import {
  fetchMoodAverage,
  fetchMoodChart,
  fetchWaypointsTracking,
} from 'pages/Dashboard/services/tracking.services';
import LoadingButton from 'components/Common/LoadingButton';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import ContentLoader from 'components/Common/ContentLoader';
import { WaypointTrackingAPIResponse, WaypointType } from 'pages/Dashboard/types/waypoints.types';
import ScrollIndicator from 'components/Common/ScrollIndicator';
import HealthRecordNew from 'pages/Dashboard/HealthRecordsNew';
import RecordRefreshDrawer from 'pages/Dashboard/RecordRefreshDrawer';
import MedicalHistoryDrawer from 'pages/Dashboard/MedicalHistoryDrawer';
import { PatientRecordRetrievalStatus, Session, Status } from 'pages/Dashboard/types';
import { createHrRefresh, healthRecordRefreshHistory } from 'pages/Dashboard/services/healthrecords.services';
import { HrRefreshCreatePayload, HrRefreshHistoryStatus } from 'pages/Dashboard/types/healthRecords.types';
import HealthRecordRefresh from 'pages/Dashboard/HealthRecordRefresh';
import { fetchSessionList } from 'pages/Dashboard/services/sessionrecording.services';
import Sessions from 'pages/Dashboard/components/SessionRecording/Sessions';
import theme from 'theme';
import RecordSession from 'pages/Dashboard/components/SessionRecording/RecordSession';

export default function PatientDetailNew() {
  const { id } = useParams();
  const location = useLocation();
  const { refreshId, hrCount } = location.state || {};
  const {
    http,
    providerId,
    superAdminId,
    isNewVersionEnabled,
    isHrRefreshEnabled,
    providerName: loggedInProvider,
    selectedProviderName,
    isSessionRecordingEnabled,
  } = useHttp();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [toastProps, setToastProps] = React.useState<ToastProps | null>(null);
  const [selectTimeFrame, setSelectTimeFrame] = React.useState(
    TimeFrameOptions.LAST_30_DAYS as string,
  );
  const [showRecordRefreshDrawer, setShowRecordRefreshDrawer] = React.useState(false);
  const [showMedicalHistoryDrawer, setShowMedicalHistoryDrawer] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [selectedChip, setSelectedChip] = React.useState(false);
  const [refreshIdToUse, setRefreshIdToUse] = React.useState(null);

  const [selectedTab, setSelectedTab] = React.useState(0);

  const [sessions, setSessions] = React.useState<Session[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  const [cb, setCb] = React.useState<() => void>(() => null);
  const [recordingStatus, setRecordingStatus] = React.useState<Status>(Status.DEFAULT);
  const [isEditingNotes, setIsEditingNotes] = React.useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget ?? null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setToastProps(null);
  };

  const onChangeTimeFrame = (timeFrameValue: string) => {
    trackMixpanelEvent(MixpanelEventName.MOOD_AND_WAYPOINT_TIME_FRAME_CHANGED, {
      'patient id': id ?? '',
      'time frame': timeFrameValue,
    }, true);
    setSelectTimeFrame(timeFrameValue);
  };

  const onError = (message: string) => {
    Logger.error(message);
    setToastProps({
      message,
      open: true,
      type: ToastType.error,
    });
  };

  const medications = useQuery({
    queryKey: [medicationsUrls.listMedications.queryUrl, providerId, id, selectedChip
      ? refreshIdToUse : undefined],
    queryFn: fetchAllMedicationList(http.get),
    onError: () => onError('Error fetching patient medications'),
  });

  const enableQueries = React.useMemo(() => !medications?.isFetching, [medications?.isFetching]);

  const medicationChartHistory = useQuery({
    queryKey: [medicationsUrls.medicationHistory.queryUrl, providerId, id],
    queryFn: fetchMedicationChartHistory(http.get),
    onError: () => onError('Error fetching patient medication chart history'),
  });

  const [
    { data: patientDetails, isFetching: patientDetailsFetching, isError: patientDetailsError },
    diagnosis,
    familyHistory,
    symptoms,
    currentSymptoms,
    allergies,
    hospitalizations,
    labs,
    patientProviders,
    lifestyle,
    backgrounds,
    patientPrimaryCareProviders,
    patientMentalHealthProviders,
  ] = useQueries([
    {
      queryKey: [patientUrls.patientDetails.queryUrl, providerId, id],
      queryFn: fetchPatientDetails(http.get),
      onError: () => onError('Error fetching patient details'),
      enabled: enableQueries,
    },
    {
      queryKey: [diagnosisUrls.listDiagnosis.queryUrl, providerId, id, selectedChip
        ? refreshIdToUse : undefined],
      queryFn: fetchDiagnosisListCombined(http.get),
      onError: () => onError('Error fetching patient diagnosis'),
      enabled: enableQueries,
    },

    {
      queryKey: [familyHistoryUrls.listFamilyHistory.queryUrl, providerId, id,
        selectedChip ? refreshIdToUse : undefined],
      queryFn: fetchFamilyHistoryList(http.get),
      onError: () => onError('Error fetching patient family history'),
      enabled: enableQueries,
    },
    {
      queryKey: [symptomsUrls.listSymptoms.queryUrl, providerId, id],
      queryFn: fetchSymptomList(http.get),
      onError: () => onError('Error fetching patient symptoms'),
      enabled: enableQueries,
    },
    {
      queryKey: [symptomsUrls.listSymptoms.queryUrl, providerId, id, 'current'],
      queryFn: fetchCurrentSymptomList(http.get),
      onError: () => onError('Error fetching patient current symptoms'),
      enabled: enableQueries,
    },
    {
      queryKey: [allergiesUrls.listAllergies.queryUrl, providerId, id, selectedChip
        ? refreshIdToUse : undefined],
      queryFn: fetchAllergyList(http.get),
      onError: () => onError('Error fetching patient allergies'),
      enabled: enableQueries,
    },
    {
      queryKey: [hospitalizationsUrls.listHospitalizations.queryUrl, providerId, id, selectedChip
        ? refreshIdToUse : undefined],
      queryFn: fetchHospitalizationList(http.get),
      onError: () => onError('Error fetching patient hospitalizations'),
      enabled: enableQueries,
    },
    {
      queryKey: [labsUrls.listLabs.queryUrl, providerId, id, selectedChip
        ? refreshIdToUse : undefined],
      queryFn: fetchLabList(http.get),
      onError: () => onError('Error fetching patient labs'),
      enabled: enableQueries,
    },
    {
      queryKey: [patientProviderUrls.listPatientProviders.queryUrl, providerId, id, selectedChip
        ? refreshIdToUse : undefined],
      queryFn: fetchAllPatientProvidersList(http.get),
      onError: () => onError('Error fetching patient providers'),
      enabled: enableQueries,
    },
    {
      queryKey: [lifestyleUrls.listLifestyle.queryUrl, providerId, id],
      queryFn: fetchLifeStyleList(http.get),
      onError: () => onError('Error fetching patient lifestyle'),
      enabled: enableQueries,
    },
    {
      queryKey: [backgroundsUrls.listBackgrounds.queryUrl, providerId, id],
      queryFn: fetchBackgroundList(http.get),
      onError: () => onError('Error fetching patient backgrounds'),
      enabled: enableQueries,
    },
    {
      queryKey: [patientProviderUrls.listPatientPrimaryCareProviders.queryUrl, providerId, id],
      queryFn: fetchPatientPrimaryCareProviderList(http.get),
      onError: () => onError('Error fetching patient primary care providers'),
      enabled: enableQueries,
    },
    {
      queryKey: [patientProviderUrls.listPatientMentalHealthProviders.queryUrl, providerId, id],
      queryFn: fetchPatientMentalHealthProviderList(http.get),
      onError: () => onError('Error fetching patient mental health providers'),
      enabled: enableQueries,
    },
    {
      queryKey: [
        patientProviderUrls.listPatientPrimaryCareProviders.queryUrl,
        providerId,
        id,
        'current',
      ],
      queryFn: fetchPatientCurrentPrimaryCareProviderList(http.get),
      onError: () => onError('Error fetching patient current primary care providers'),
      enabled: enableQueries,
    },
  ]);

  const currentMoodAverage = useQuery(
    [`${trackingUrls.moodAverage.queryUrl}`, providerId, id, selectTimeFrame, 'current'],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const previousMoodAverage = useQuery(
    [`${trackingUrls.moodAverage.queryUrl}`, providerId, id, selectTimeFrame, 'previous'],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const whoIAmCategories = useQuery([whoIam.categories.queryUrl, providerId, id], {
    queryFn: fetchWhoIamCategories(http.get),
  });

  const currentWaypoint = useQuery(
    [`${trackingUrls.waypoints.queryUrl}`, providerId, id, selectTimeFrame, 'current'],
    {
      queryFn: fetchWaypointsTracking(http.get),
      onError: () => onError('Error fetching patient waypoint'),
      enabled: enableQueries,
      select: (data) => {
        const filteredWaypoints = data?.waypoints.filter(
          (waypoint) => waypoint.waypoint.type !== WaypointType.WAYPOINT_TYPE_APPLE_HEALTH,
        );

        return {
          waypoints: filteredWaypoints,
        } as WaypointTrackingAPIResponse;
      },
    },
  );

  const previousWaypoint = useQuery(
    [`${trackingUrls.waypoints.queryUrl}`, providerId, id, selectTimeFrame, 'previous'],
    {
      queryFn: fetchWaypointsTracking(http.get),
      onError: () => onError('Error fetching patient waypoint'),
      enabled: enableQueries,
      select: (data) => {
        const filteredWaypoints = data?.waypoints.filter(
          (waypoint) => waypoint.waypoint.type !== WaypointType.WAYPOINT_TYPE_APPLE_HEALTH,
        );

        return {
          waypoints: filteredWaypoints,
        } as WaypointTrackingAPIResponse;
      },
    },
  );

  const moodChartHappiness = useQuery(
    [
      `${trackingUrls.moodList.queryUrl}`,
      providerId,
      id,
      selectTimeFrame,
      isNewVersionEnabled ? MoodV2.MOOD_PLEASANTNESS : Mood.MOOD_HAPPINESS,
      'charts',
    ],
    {
      queryFn: fetchMoodChart(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const moodChartAnxiousness = useQuery(
    [
      `${trackingUrls.moodList.queryUrl}`,
      providerId,
      id,
      selectTimeFrame,
      Mood.MOOD_ANXIOUSNESS,
      'charts',
    ],
    {
      queryFn: fetchMoodChart(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const moodChartMotivation = useQuery(
    [
      `${trackingUrls.moodList.queryUrl}`,
      providerId,
      id,
      selectTimeFrame,
      isNewVersionEnabled ? MoodV2.MOOD_ENERGY : Mood.MOOD_MOTIVATION,
      'charts',
    ],
    {
      queryFn: fetchMoodChart(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: enableQueries,
    },
  );

  const { mutateAsync: fetchExportData, isLoading: exportLoading } = useMutation(
    (asPDF?: boolean) => fetchPatientExportData(http.post, providerId, id ?? '', asPDF),
    {
      onSuccess: (dataBlob) => {
        const fileName = `${patientName} Report`;
        const blob = new Blob([dataBlob], {
          type: dataBlob.type === 'application/pdf' ? dataBlob.type : 'application/vnd.ms-excel',
        });
        const url = window.URL.createObjectURL(blob);
        downloadUrl(url, fileName);
      },
    },
  );

  const providerAssociations = useQuery(
    [`${providers.providerAssociationList.queryUrl}`, providerId, 'patient-detail'],
    {
      queryFn: fetchProvidersAssociationsList(http.get),
    },
  );

  const healthRecordStatus = useQuery(
    [`${patientUrls.patientHealthRecordStatus.queryUrl}`, providerId, id],
    {
      queryFn: fetchPatientHealthRecordStatus(http.get),
    },
  );


  const { mutate: fetchSessions, isLoading: isFetching } = useMutation(
    ({ offset }: { offset: number }) => fetchSessionList(http.get, providerId, id ?? '', `?offset=${offset}&limit=10`),
    {
      onSuccess: (data) => {
        setTotal(+data.pagination.total);
        if (data.sessions.length > 0) {
          setSessions((prev) => [
            ...(data.pagination.offset - 10 > 0 ? prev : []),
            ...data.sessions,
          ]);
        }
      },
    },
  );


  const isMoodLoading = React.useMemo(
    () => moodChartAnxiousness.isLoading
      || moodChartMotivation.isLoading
      || moodChartHappiness.isLoading
      || currentMoodAverage.isLoading
      || previousMoodAverage.isLoading,
    [
      moodChartAnxiousness.isLoading,
      moodChartMotivation.isLoading,
      moodChartHappiness.isLoading,
      currentMoodAverage.isLoading,
      previousMoodAverage.isLoading,
    ],
  );

  const isTrackingLoading = React.useMemo(
    () => currentWaypoint.isLoading
      || previousWaypoint.isLoading
      || isMoodLoading,
    [
      currentWaypoint.isLoading,
      previousWaypoint.isLoading,
      isMoodLoading,
    ],
  );

  const isPatientProfileLoading = React.useMemo(
    () => whoIAmCategories.isLoading,
    [whoIAmCategories.isLoading],
  );

  const defaultCategories = {
    categories: [],
  };

  const patientName = React.useMemo(() => {
    const firstName = capitalize(patientDetails?.patient?.name?.firstName ?? '');
    const lastName = capitalize(patientDetails?.patient?.name?.lastName ?? '');
    return `${firstName} ${lastName}`;
  }, [patientDetails?.patient?.name?.firstName, patientDetails?.patient?.name?.lastName]);

  const providerName = React.useMemo(() => {
    const firstName = capitalize(patientDetails?.patient?.provider?.firstName ?? '');
    const lastName = capitalize(patientDetails?.patient?.provider?.lastName ?? '');
    return `${firstName} ${lastName}`;
  }, [patientDetails?.patient?.provider?.firstName, patientDetails?.patient?.provider?.lastName]);


  const handleMedicationHistoryDrawer = () => {
    setShowMedicalHistoryDrawer(true);
    trackMixpanelEvent(MixpanelEventName.MEDICAL_HISTORY_DRAWER_OPENED, {
      'patient id': id ?? '',
      'history status': refreshStatus ?? '',
      'provider id': providerId ?? '',
      'logged in provider name': loggedInProvider ?? '',
      'selected provider name': selectedProviderName ?? '',
    });
  };

  const toggleHistoryDrawer = () => {
    setShowMedicalHistoryDrawer((prev) => !prev);
  };

  const handleRecordRefreshDrawer = () => {
    setShowRecordRefreshDrawer(true);
    trackMixpanelEvent(MixpanelEventName.REFRESH_RECORDS_BUTTON_CLICKED, {
      'patient id': id ?? '',
      'provider id': providerId ?? '',
      'logged in provider name': loggedInProvider ?? '',
      'selected provider name': selectedProviderName ?? '',
    });
  };

  const toggleRecordRefreshDrawer = () => {
    setShowRecordRefreshDrawer((prev) => !prev);
  };

  const { isLoading } = healthRecordStatus;

  const isRecordStatusRetreived = healthRecordStatus.data?.status?.recordRetrievalStatus
  !== PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_RETRIEVED;

  React.useEffect(() => {
    if (isRecordStatusRetreived) {
      trackMixpanelEvent(MixpanelEventName.HEALTH_RECORDS_FETCHING_IN_PROGRESS, {
        'patient id': id ?? '',
        'provider id': providerId ?? '',
        'logged in provider name': loggedInProvider ?? '',
        'selected provider name': selectedProviderName ?? '',
      });
    }
  }, [id, isRecordStatusRetreived, providerId, selectedProviderName, loggedInProvider]);

  const onClose = () => {
    setShowRecordRefreshDrawer(false);
    setSelectedDate(null);
  };

  const getHrRefreshHistory = useQuery({
    queryKey: [healthRecordRefreshUrls.hrRefreshHistory.queryUrl, providerId, id],
    queryFn: healthRecordRefreshHistory(http.get),
    onError: () => onError('Error fetching medical records refresh history'),
    enabled: enableQueries && isHrRefreshEnabled,
  });


  const refreshIdFromHistory = getHrRefreshHistory?.data?.refreshHistory[0]?.refreshId;
  React.useEffect(() => {
    if (refreshIdFromHistory || refreshId) {
      const newRefreshId = refreshId || refreshIdFromHistory;
      setRefreshIdToUse(newRefreshId);
    }
  }, [refreshId, refreshIdFromHistory, getHrRefreshHistory.data]);

  const { mutate: hrRefreshCreation, isLoading: isRecordRefreshLoading } = useMutation({
    mutationFn: (
      payload: HrRefreshCreatePayload,
    ) => createHrRefresh(http.post, providerId, id as string, { ...payload }),
    onSettled: () => {
      onClose();
      getHrRefreshHistory.refetch();
    },
    onSuccess: () => {
      setToastProps({ message: 'Record refresh scheduled successfully', open: true, type: ToastType.success });
    },
    onError: (e) => {
      const errorMessage = ((e instanceof Error ? e.message : e) || 'You are not authorised to perform this action') as string;
      setToastProps({ message: errorMessage, open: true, type: ToastType.error });
    },
    retry: 0,
  });

  const hrCountFromHistory = getHrRefreshHistory?.data?.refreshHistory[0]?.hrCount ?? 0;

  React.useEffect(() => {
    const { refreshId: localRefreshId, notificationId } = location.state || {};
    // Detect if page was refreshed (sessionStorage flag)
    const isPageReloaded = sessionStorage.getItem('wasPageReloaded') === 'true';
    if (isPageReloaded) {
      // If reloaded, clear state and reset chip
      setSelectedChip(false);
      sessionStorage.removeItem('selectedChip');
      sessionStorage.removeItem('wasPageReloaded');
    }
    else if (localRefreshId && notificationId && hrCount > 0) {
      // If navigated from notification, select chip and store state when hrCount > 0
      setSelectedChip(true);
      sessionStorage.setItem('selectedChip', 'true');
    }
    else if (localRefreshId && notificationId && hrCount === 0) {
      // If navigated from notification, select chip and store state when hrCount = 0
      setSelectedChip(false);
      sessionStorage.setItem('selectedChip', 'false');
    }
    const handleBeforeUnload = () => {
      if (window.location.pathname === `/patient/${id}`) {
        sessionStorage.setItem('wasPageReloaded', 'true');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location.state, id, hrCount]);

  const refreshStatus = getHrRefreshHistory.data?.refreshHistory[0]?.status;

  const handleChipClick = () => {
    if (hrCount > 0 || hrCountFromHistory > 0 || !selectedChip) {
      trackMixpanelEvent(MixpanelEventName.SHOW_RECORDS_FROM_LAST_REFRESH_BUTTON_SELECTED, {
        'number of records': hrCount || hrCountFromHistory,
        'fetch in progress yes/no': refreshStatus === HrRefreshHistoryStatus.IN_PROGRESS ? 'yes' : 'no',
        'provider id': providerId ?? '',
        'logged in provider name': loggedInProvider ?? '',
        'selected provider name': selectedProviderName ?? '',
      });
      setSelectedChip((prev) => !prev);
    }
  };

  const configureTabs: TabContext[] = [
    {
      header: 'MEDICAL HISTORY',
      content: (
        <Grid
          pt={isRecordStatusRetreived && !isLoading ? 3 : 7}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <HealthRecordRefresh
              isRecordStatusRetreived={isRecordStatusRetreived}
              isLoading={isLoading}
              handleRecordRefreshDrawer={handleRecordRefreshDrawer}
              getHrRefreshHistory={getHrRefreshHistory}
              refreshStatus={refreshStatus}
              handleMedicationHistoryDrawer={handleMedicationHistoryDrawer}
              hrCount={hrCount}
              hrCountFromHistory={hrCountFromHistory}
              handleChipClick={handleChipClick}
              selectedChip={selectedChip}
              setSelectedChip={setSelectedChip}
            />
            <RecordRefreshDrawer
              isOpen={showRecordRefreshDrawer}
              toggleDrawer={toggleRecordRefreshDrawer}
              hrRefreshCreation={hrRefreshCreation}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              getHrRefreshHistoryLastDate={getHrRefreshHistory?.data
                ?.refreshHistory[0]?.scheduledOn}
              setSelectedChip={setSelectedChip}
            />
            <MedicalHistoryDrawer
              isOpen={showMedicalHistoryDrawer}
              toggleDrawer={toggleHistoryDrawer}
              getHrRefreshHistory={getHrRefreshHistory}
            />
            <HealthRecordNew
              diagnosis={diagnosis}
              medications={medications}
              familyHistory={familyHistory}
              symptoms={symptoms}
              currentSymptoms={currentSymptoms}
              allergies={allergies}
              hospitalizations={hospitalizations}
              providers={patientProviders}
              primaryCareProviders={patientPrimaryCareProviders}
              mentalHealthProviders={patientMentalHealthProviders}
              lifeStyle={lifestyle}
              backgrounds={backgrounds}
              labs={labs}
              medicationChartHistory={medicationChartHistory}
              isRecordStatusRetreived={isRecordStatusRetreived}
              selectedChip={selectedChip}
              getHrRefreshHistory={getHrRefreshHistory}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      header: 'PROGRESS MONITOR',
      content: (
        <Grid pt={4} container spacing={7}>
          <Grid item xs={12}>
            <PatientTracking
              timeFrame={selectTimeFrame}
              onChangeTimeFrame={onChangeTimeFrame}
              currentMoodData={
                currentMoodAverage?.data?.moods ? currentMoodAverage?.data : defaultMoodTracking
              }
              previousMoodData={
                previousMoodAverage?.data?.moods ? previousMoodAverage?.data : defaultMoodTracking
              }
              currentWaypointData={
                currentWaypoint?.data?.waypoints ? currentWaypoint.data : defaultWaypointTracking
              }
              previousWaypointData={
                previousWaypoint?.data?.waypoints ? previousWaypoint?.data : defaultWaypointTracking
              }
              moodChartHappiness={moodChartHappiness?.data || defaultMoodTracking}
              moodChartAnxiousness={moodChartAnxiousness?.data || defaultMoodTracking}
              moodChartMotivation={moodChartMotivation?.data || defaultMoodTracking}
              isMoodLoading={isMoodLoading}
              isTrackingLoading={isTrackingLoading}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      header: 'PATIENT PROFILE',
      content: (
        <Grid mt={2} container spacing={2}>
          <Grid item xs={12}>
            <ContentLoader isFetching={isPatientProfileLoading} minHeight={400} isError={false}>
              <PatientProfile
                categoriesData={whoIAmCategories?.data || defaultCategories}
                backgrounds={backgrounds}
              />
            </ContentLoader>
          </Grid>
        </Grid>
      ),
    },
    {
      header: 'SESSIONS',
      content: (
        <Sessions
          sessions={sessions}
          isFetching={isFetching}
          total={total}
          fetchSessions={fetchSessions}
          setToastProps={setToastProps}
        />
      ),
    },
  ];

  const exportData = (type: string) => {
    handleMenuClose();
    fetchExportData(type === 'pdf');
    trackMixpanelEvent(MixpanelEventName.EXPORT_DATA_CLICKED, {
      'patient id': id ?? '',
      'export type': type,
    });
  };

  const tabs = Object.keys(TimeFrameOptions).map((timeFrameOption) => ({
    header: TimeFrameOptions[timeFrameOption as keyof typeof TimeFrameOptions],
  }));

  const timeFrameValues = React.useMemo(() => Object.values(TimeFrameOptions), []);

  const selectedTimeFrame = React.useMemo(
    () => timeFrameValues.findIndex((value) => value === selectTimeFrame),
    [selectTimeFrame, timeFrameValues],
  );

  const onTabChange = (tab: number) => {
    const tabName = configureTabs[tab].header;

    trackMixpanelEvent(MixpanelEventName.PATIENT_DETAILS_TAB_SELECTED, {
      tab: tabName,
    });

    if (configureTabs[selectedTab].header === 'SESSIONS') {
      setSessions([]);
      setTotal(0);
    }
    setSelectedTab(tab);
  };

  const contextValue = React.useMemo(
    () => ({
      cb,
      setCb,
      recordingStatus,
      isEditingNotes,
      setIsEditingNotes,
      setRecordingStatus,
      ...patientDetails?.patient,
    }),
    [cb, patientDetails?.patient, recordingStatus, isEditingNotes],
  );

  return (
    <ContentLoader
      isFetching={!patientDetails || patientDetailsFetching || isRecordRefreshLoading}
      minHeight={400}
      isError={patientDetailsError}
    >
      <PatientCtx.Provider value={contextValue}>
        {!!providerAssociations.data?.providers.length && (
          <Box>
            <Typography variant='subtitle1'>{providerName}</Typography>
          </Box>
        )}
        <PageHeader
          title={patientName}
          isMasked
          subtitle={
            googleDateToDateString(patientDetails?.patient?.dateOfBirth as GoogleDate) as string
          }
        />
        <Box sx={{ m: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <LoadingButton
            variant='outlined'
            onClick={handleClick}
            startIcon={<GetAppIcon />}
            isLoading={exportLoading}
            disabled={!!superAdminId}
            sx={{ mr: isSessionRecordingEnabled ? 2 : 0 }}
            label='EXPORT DATA'
          />
          {isSessionRecordingEnabled && (
            <RecordSession
              fetchSessions={fetchSessions}
              setToastProps={setToastProps}
            />
          )}
          <Menu
            id='fade-menu'
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl as HTMLElement}
            open={open}
            onClose={handleMenuClose}
            TransitionComponent={Fade}
            sx={{ '& .MuiMenu-paper': { width: 152 } }}
          >
            <MenuItem onClick={() => exportData('excel')}>As Excel</MenuItem>
            <MenuItem onClick={() => exportData('pdf')}>As PDF</MenuItem>
          </Menu>
        </Box>
        {[Status.RECORDING, Status.PAUSED].includes(recordingStatus) && (
          <Alert
            severity='error'
            sx={{
              border: `1px solid ${theme.custom.colors.lightErrorMain}`,
              my: 3,
              '& .MuiAlert-icon': {
                color: theme.custom.colors.lightErrorMain,
              },
            }}
          >
            Your session is being recorded
          </Alert>
        )}
        <TabComponent
          tabContext={configureTabs}
          tabsHeight={48}
          renderBelowHeader={(tab) => (
            configureTabs[tab].header === 'PROGRESS MONITOR' ? (
              <Box
                display='flex'
                alignItems='center'
                flexDirection='row'
                justifyContent='flex-end'
                py={3}
              >
                <Typography color='#4C4C4C' marginRight={2}>
                  Data from past:
                </Typography>
                <Tabs
                  value={selectedTimeFrame}
                  setValue={(value) => {
                    const timeFrameValue = timeFrameValues[value as number];
                    onChangeTimeFrame(timeFrameValue);
                    trackMixpanelEvent(MixpanelEventName.DATERANGE_CLICKED, {
                      timeFrameValue,
                      clickedFrom: 'Patient details',
                    });
                  }}
                  tabContext={tabs}
                  variant='standard'
                  activeStyles={{
                    backgroundColor: '#FFF',
                    maxHeight: 24,
                  }}
                />
              </Box>
            ) : null
          )}
          onTabChange={onTabChange}
          stickHeader
        />
        {toastProps ? <Toast {...toastProps} onClose={handleClose} /> : null}
        <ScrollIndicator />
      </PatientCtx.Provider>
    </ContentLoader>
  );
}
