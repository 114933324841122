// @ts-ignore
import { Box, Grid, Link, Typography } from '@mui/material';
import { useHttp } from 'hooks/use-fetch';
import HealthWaypointChartsSection from 'pages/Dashboard/HealthWaypointsChartsSection';
import WaypointTrackingSection from 'pages/Dashboard/WaypointTrackingSection';
import AppleHealthActivitySummaryChart, {
  DataKey,
} from 'pages/Dashboard/components/AppleHealthActivitySummaryChart';
import AppleHealthChartsWrapper from 'pages/Dashboard/components/AppleHealthChartsWrapper';
import LoggedMoods from 'pages/Dashboard/components/LoggedMoods';
import MoodCardV1 from 'pages/Dashboard/components/MoodCard';
import MoodCardV2 from 'pages/Dashboard/components/MoodCardV2';
import { PatientCtx } from 'pages/Dashboard/constants';
import {
  fetchGamesTracking,
  fetchMostLoggedMetrics,
  fetchSomaticScore,
  fetchWaypointTracking,
} from 'pages/Dashboard/services/tracking.services';
import { fetchPatientWaypointList } from 'pages/Dashboard/services/waypoints.services';
import {
  Mood,
  MoodOptions,
  MoodTrackingAverageResponse,
  MoodTrackingListResponse,
  MoodV2,
} from 'pages/Dashboard/types/moods.types';
import {
  MoodFactorDomainsResponse,
  MostLoggedMetricsResponse,
  SomaticScoreResponse,
  StroopTimeSeriesAPIResponse,
  WaypointTrackingAPIResponse,
  WaypointType,
} from 'pages/Dashboard/types/waypoints.types';
import {
  TimeFrameOptions,
  getChartDateRanges,
  getFilteredWaypointsData,
  getMoodOptions,
  waypointBarSizeMap,
} from 'pages/Dashboard/utils/trackingUtils';
import * as React from 'react';
import { useQueries, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import { gamesUrls, trackingUrls, wayPointsUrls } from 'utils/apiUrls';


const stroopInfoText = 'The Stroop test is a neuropsychological test that measures how well a person can focus on one thing while ignoring others.';
const somaticScoreText = 'The Somatic score reflects how well the patient is doing with things like sleep, daylight exposure, and exercise — areas they can control to improve their overall mental well-being. The score is calculated using Apple Health data.';

enum MostLoggedMetrics {
  MOOD = 'MOOD',
  SITUATIONS = 'SITUATIONS',
  DOMAINS = 'DOMAINS',
}

type Waypoint = {
  id: string;
  title?: string;
  subTitle?: string;
};

interface PatientTrackingProps {
  timeFrame: string;
  onChangeTimeFrame: (v: string) => void;
  currentMoodData: MoodTrackingAverageResponse;
  previousMoodData: MoodTrackingAverageResponse;
  currentWaypointData: WaypointTrackingAPIResponse;
  previousWaypointData: WaypointTrackingAPIResponse;
  moodChartHappiness: MoodTrackingListResponse;
  moodChartAnxiousness: MoodTrackingListResponse;
  moodChartMotivation: MoodTrackingListResponse;
  isTrackingLoading: boolean;
  isMoodLoading: boolean;
}

export default function PatientTracking({
  timeFrame: selectedTimeFrame,
  onChangeTimeFrame,
  currentMoodData,
  previousMoodData,
  currentWaypointData,
  previousWaypointData,
  moodChartHappiness,
  moodChartAnxiousness,
  moodChartMotivation,
  isTrackingLoading,
  isMoodLoading,
}: PatientTrackingProps) {
  const patient = React.useContext(PatientCtx);
  const { http, providerId, isNewVersionEnabled } = useHttp();
  const { id: patientId } = useParams();

  const [sourceId, setSourceId] = React.useState<string>('');
  const [moodId, setMoodId] = React.useState('');
  const [dataMap, setDataMap] = React.useState<Record<string, boolean>>({});

  const [isSixMonthDataFetched, setIsSixMonthDataFetched] = React.useState(false);

  const timeFrame = React.useMemo(
    () => (selectedTimeFrame === TimeFrameOptions.LAST_30_DAYS && !isSixMonthDataFetched
      ? TimeFrameOptions.LAST_6_MONTHS
      : selectedTimeFrame),
    [selectedTimeFrame, isSixMonthDataFetched],
  );


  const fetchPatientWaypointsResult = useQuery(
    [`${wayPointsUrls.listPatientWaypoints.queryUrl}`, patientId],
    {
      queryFn: fetchPatientWaypointList(http.get),
      enabled: !!providerId && !!patientId,
    },
  );

  const appleHealthWaypointMap = React.useMemo(() => ({} as Record<string, string>), []);
  const appleHealthWaypoints = React.useMemo(
    () => fetchPatientWaypointsResult?.data?.waypoints?.reduce((acc, w, i) => {
      if (w?.waypoint?.type === WaypointType.WAYPOINT_TYPE_DERIVED
         || w?.waypoint?.type === WaypointType.WAYPOINT_TYPE_APPLE_HEALTH) {
        appleHealthWaypointMap[w?.waypoint?.name] = w?.waypoint?.id;
        acc.push({
          id: w?.waypoint?.id,
          title: w?.waypoint?.displayName,
          subTitle: w?.waypoint?.description,
        });
      }

      return acc;
    }, [] as Waypoint[]) || [],
    [appleHealthWaypointMap, fetchPatientWaypointsResult?.data?.waypoints],
  );


  const fetchMostLoggedMoodsResult = useQuery(
    [
      `${trackingUrls.mostLoggedMetrics.queryUrl}`,
      providerId,
      patientId,
      selectedTimeFrame,
      MostLoggedMetrics.MOOD,
    ],
    {
      queryFn: fetchMostLoggedMetrics(http.get),
      enabled: !!providerId && !!patientId && isNewVersionEnabled,
    },
  );

  const fetchMostLoggedSituationResult = useQuery(
    [
      `${trackingUrls.mostLoggedMetrics.queryUrl}`,
      providerId,
      patientId,
      selectedTimeFrame,
      moodId,
      'mood',
    ],
    {
      queryFn: fetchMostLoggedMetrics(http.get),
      enabled: !!providerId && !!patientId && isNewVersionEnabled,
    },
  );

  const mostLoggedMetrics = React.useMemo(
    () => ({
      mood: (fetchMostLoggedMoodsResult?.data as MostLoggedMetricsResponse)?.percents || [],
      factors: (fetchMostLoggedSituationResult?.data as MoodFactorDomainsResponse)
        ?.moodFactorDomains || [],
      moodsLoading: fetchMostLoggedMoodsResult?.isLoading,
      factorsLoading: fetchMostLoggedSituationResult?.isLoading,
    }),
    [
      fetchMostLoggedMoodsResult?.data,
      fetchMostLoggedMoodsResult?.isLoading,
      fetchMostLoggedSituationResult?.isLoading,
      fetchMostLoggedSituationResult?.data,
    ],
  );
  const queries = React.useMemo(
    () => [
      {
        queryKey: [`${gamesUrls.stroopTimeSeries.queryUrl}`, providerId, patientId, timeFrame],
        queryFn: fetchGamesTracking(http.get),
        enabled: !!providerId && !!patientId,
      },
    ],
    [providerId, patientId, timeFrame, http.get],
  );

  const fetchPatientWaypointTrackingResult = useQuery(
    [
      `${trackingUrls.waypointDetails.queryUrl}-${sourceId}-${timeFrame}`,
      providerId,
      patientId,
      appleHealthWaypointMap.sleepDerived,
      timeFrame,
      sourceId,
    ],
    {
      queryFn: fetchWaypointTracking(http.get, true),

      enabled:
        !!providerId && !!patientId && !!sourceId && !!appleHealthWaypointMap.sleepDerived,
    },
  );

  const fetchSomaticScoreResult = useQuery(
    [
      `${trackingUrls.somaticScore.queryUrl}-${timeFrame}`,
      providerId,
      patientId,
      timeFrame,
    ],
    {
      queryFn: fetchSomaticScore(http.get),
      enabled:
        !!providerId && !!patientId && isNewVersionEnabled,
    },
  );

  const queriesResults = useQueries(queries);

  React.useEffect(() => {
    const waypointsFetched = fetchPatientWaypointsResult.isFetched;
    const ahWaypointsFetched = queriesResults.every((q) => q.isFetched);
    const moodsDetails = !isNewVersionEnabled || fetchMostLoggedMoodsResult.isFetched;

    if (
      !isSixMonthDataFetched
      && timeFrame === TimeFrameOptions.LAST_6_MONTHS
      && waypointsFetched
      // && sourcesLoaded
      && ahWaypointsFetched
      // && sleepLoaded
      && moodsDetails
    ) {
      setIsSixMonthDataFetched(true);
    }
  }, [
    fetchPatientWaypointTrackingResult.isFetched,
    fetchPatientWaypointsResult.isFetched,
    isSixMonthDataFetched,
    queriesResults,
    sourceId,
    timeFrame,
    fetchMostLoggedMoodsResult,
    isNewVersionEnabled,
  ]);


  const stroopTimeSeriesData = React.useMemo(
    () => (queriesResults[queries.length - 1]?.data as StroopTimeSeriesAPIResponse)?.data || [],
    [queriesResults, queries.length],
  );

  const stroopTimeSeriesAvgData = React.useMemo(
    () => (queriesResults[
      queries.length - 1
    ]?.data as StroopTimeSeriesAPIResponse)?.absoluteAvgValue
      || 0,
    [queriesResults, queries.length],
  );

  const somaticScoreTimeSeriesData = React.useMemo(() => {
    const data = (fetchSomaticScoreResult?.data as SomaticScoreResponse)?.data || [];
    return data;
  }, [fetchSomaticScoreResult?.data]);

  const somaticScoreTimeSeriesAvgData = React.useMemo(() => {
    const data = (fetchSomaticScoreResult?.data as SomaticScoreResponse)?.absoluteAvgValue || 0;
    return data;
  }, [fetchSomaticScoreResult?.data]);

  React.useEffect(() => {
    if (stroopTimeSeriesData.length > 0 && timeFrame === TimeFrameOptions.LAST_6_MONTHS) {
      setDataMap((prev) => ({ ...prev, stroop: true }));
    }
  }, [stroopTimeSeriesData.length, timeFrame]);

  const hasMostLoggedMetrics = React.useMemo(
    () => (mostLoggedMetrics.mood.length > 0
      || mostLoggedMetrics.factors.length > 0)
      && !(mostLoggedMetrics.factorsLoading || mostLoggedMetrics.moodsLoading),
    [mostLoggedMetrics.factors.length,
      mostLoggedMetrics.factorsLoading,
      mostLoggedMetrics.mood.length,
      mostLoggedMetrics.moodsLoading],
  );

  React.useEffect(() => {
    if (hasMostLoggedMetrics && timeFrame === TimeFrameOptions.LAST_6_MONTHS) {
      setDataMap((prev) => ({ ...prev, mostLoggedMetrics: true }));
    }
  }, [hasMostLoggedMetrics, stroopTimeSeriesData.length, timeFrame]);

  const isStroopTimeSeriesDataLoading = React.useMemo(
    () => queriesResults[queries.length - 1]?.isFetching,
    [queriesResults, queries.length],
  );

  const isSomaticScoresDataLoading = React.useMemo(
    () => fetchSomaticScoreResult?.isFetching,
    [fetchSomaticScoreResult?.isFetching],
  );

  const numberOfCards = appleHealthWaypoints.length;


  const getChartData = React.useCallback((mood: Mood | MoodV2) => {
    if (mood === Mood.MOOD_HAPPINESS || mood === MoodV2.MOOD_PLEASANTNESS) {
      return moodChartHappiness;
    }
    else if (mood === Mood.MOOD_MOTIVATION || mood === MoodV2.MOOD_ENERGY) {
      return moodChartMotivation;
    }
    return moodChartAnxiousness;
  }, [moodChartHappiness, moodChartMotivation, moodChartAnxiousness]);

  const groupedAllWaypoints = getFilteredWaypointsData(
    currentWaypointData.waypoints,
    previousWaypointData.waypoints,
  );

  const moodOptions: MoodOptions[] = React.useMemo(
    () => getMoodOptions(currentMoodData.moods, previousMoodData.moods, isNewVersionEnabled),
    [currentMoodData.moods, isNewVersionEnabled, previousMoodData.moods],
  );

  const yAxisProps = getChartDateRanges(timeFrame as TimeFrameOptions) as {
    startTime: number;
    endTime: number;
    ticks: number[];
    isInDays: boolean;
  };

  const MoodCard = isNewVersionEnabled ? MoodCardV2 : MoodCardV1;

  const gridSize = React.useMemo(() => {
    if (isNewVersionEnabled) { return (hasMostLoggedMetrics || dataMap.mostLoggedMetrics) ? 4 : 6; }
    return 4;
  }, [dataMap.mostLoggedMetrics, hasMostLoggedMetrics, isNewVersionEnabled]);

  const onSelectMood = (selectedMoodId: string) => {
    setMoodId(selectedMoodId);
  };
  return (
    <Box>
      <Grid container spacing={2} mb={3}>
        {moodOptions.map((option) => (
          <MoodCard
            timeFrame={selectedTimeFrame}
            cardData={option}
            chartData={getChartData(option.mood)?.moods || []}
            noData={getChartData(option.mood)?.moods?.length === 0}
            isMoodLoading={isMoodLoading}
            gridSize={gridSize}
          />
        ))}
        {(hasMostLoggedMetrics || dataMap.mostLoggedMetrics) && isNewVersionEnabled && (
          <LoggedMoods
            timeFrame={timeFrame}
            data={mostLoggedMetrics}
            noData={!hasMostLoggedMetrics}
            onChangeTimeFrame={onChangeTimeFrame}
            onSelectMood={onSelectMood}
          />
        )}
      </Grid>
      {numberOfCards === 0 && (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          bgcolor='#0288D120'
          border='1px dashed'
          borderColor={theme.custom.colors.primaryMain}
          borderRadius={1.5}
          padding={1.5}
          maxWidth='lg'
          marginX='auto'
          mb={3}
        >
          <Typography variant='body1' mt={1} textAlign='center'>
            Health data is not currently available.
            <br />
            The patient either hasn’t enabled access on their phone.
          </Typography>
        </Box>
      )}
      <HealthWaypointChartsSection
        waypointsList={appleHealthWaypoints}
        appleHealthWaypointMap={appleHealthWaypointMap}
        patientId={patientId ?? ''}
        timeFrame={timeFrame as TimeFrameOptions}
        providerId={providerId}
        onChangeTimeFrame={onChangeTimeFrame}
      />
      <Grid container spacing={2} justifyContent='center' mb={3}>

        {(stroopTimeSeriesData.length > 0 || dataMap.stroop) && (
        <AppleHealthChartsWrapper
          timeFrame={timeFrame}
          title='Cognitive performance'
          subTitle='(Stroop test)'
          headerIcon='grain'
          isAppleHealthWaypoint={false}
          gridSize={6}
          showTimeFrame={false}
          isLoading={isStroopTimeSeriesDataLoading}
          noData={stroopTimeSeriesData.length === 0}
          infoIcon
          infoIconText={(
            <Box>
              <Typography mb={2} fontSize={18} fontWeight='normal'>
                {stroopInfoText}
              </Typography>
              <Link
                underline='hover'
                target='_blank'
                href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5388755'
                fontSize={18}
                color={theme.custom.colors.primaryMain}
                fontWeight='normal'
              >
                Learn more
              </Link>
            </Box>
              )}
          renderChart={(
            <AppleHealthActivitySummaryChart
              chartData={stroopTimeSeriesData}
              dataKey={DataKey.Score}
              units=''
              yAxisProps={yAxisProps}
              yAxisDefinition='Score'
              averageValue={stroopTimeSeriesAvgData}
              showAverages={false}
              barSize={waypointBarSizeMap[`${timeFrame as TimeFrameOptions}-${6}`]}
              sourceOptions={[]}
              sourceId=''
              setSourceId={setSourceId}
            />
              )}
        />
        )}
        {isNewVersionEnabled && somaticScoreTimeSeriesData.length > 0 && (
        <AppleHealthChartsWrapper
          timeFrame={timeFrame}
          title='Somatic score'
          subTitle='(Healthy habits indicator)'
          headerIcon='graphic_eq'
          isAppleHealthWaypoint={false}
          gridSize={6}
          showTimeFrame={false}
          isLoading={isSomaticScoresDataLoading}
          noData={somaticScoreTimeSeriesData.length === 0}
          infoIcon
          infoIconText={(
            <Box>
              <Typography mb={2} fontSize={18} fontWeight='normal'>
                {somaticScoreText}
              </Typography>
            </Box>
              )}
          renderChart={(
            <AppleHealthActivitySummaryChart
              chartData={somaticScoreTimeSeriesData}
              dataKey={DataKey.SomaticScore}
              units=''
              yAxisProps={yAxisProps}
              yAxisDefinition='Score'
              averageValue={somaticScoreTimeSeriesAvgData}
              showAverages={false}
              barSize={waypointBarSizeMap[`${timeFrame as TimeFrameOptions}-${6}`]}
              barColor={theme.custom.colors.lightTertiaryMain}
              sourceOptions={[]}
              sourceId=''
              setSourceId={setSourceId}
            />
              )}
        />
        )}
      </Grid>
      <WaypointTrackingSection
        patient={patient}
        timeFrame={timeFrame}
        currentWaypoints={currentWaypointData?.waypoints}
        previousWaypoints={previousWaypointData?.waypoints}
        allWaypoints={groupedAllWaypoints}
      />
    </Box>
  );
}
