import { useHttp } from 'hooks/use-fetch';
import {
  fetchWaypointSources,
  fetchWaypointTracking,
} from 'pages/Dashboard/services/tracking.services';
import { fetchPatientWaypointList } from 'pages/Dashboard/services/waypoints.services';
import { WaypointType } from 'pages/Dashboard/types/waypoints.types';
import { TimeFrameOptions } from 'pages/Dashboard/utils/trackingUtils';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { trackingUrls, wayPointsUrls } from 'utils/apiUrls';

type DateObj = {
  year: number;
  month: number;
  day: number;
};

function isWithinLast30Days(dateObj: DateObj): boolean {
  // Convert object to a JavaScript Date object
  try {
    if (!dateObj) {
      return false;
    }
    const givenDate = new Date(dateObj?.year, dateObj.month - 1, dateObj?.day);

    const currentDate = new Date();
    const pastDate = new Date();
    pastDate.setDate(currentDate.getDate() - 30);

    return givenDate >= pastDate && givenDate <= currentDate;
  }
  catch (e) {
    return false;
  }
}
const usePatientTrackingSummary = (id: string) => {
  const { http, providerId } = useHttp();
  const fetchPatientWaypointsResult = useQuery(
    [`${wayPointsUrls.listPatientWaypoints.queryUrl}`, id],
    {
      queryFn: fetchPatientWaypointList(http.get),
      enabled: !!providerId && !!id,
    },
  );

  const [sourceId, setSourceId] = useState('');
  const [sourceIdActivity, setSourceIdActivity] = useState('');
  const [sourceIdPlatformSleep, setSourceIdPlatformSleep] = useState<'android' | 'ios' | undefined>();
  const [sourceIdPlatformActivity, setSourceIdPlatformActivity] = useState<'android' | 'ios' | undefined>();

  const waypoints = fetchPatientWaypointsResult.data?.waypoints;
  const validAppleHealthWaypoints = waypoints?.filter(
    (waypoint, index) => waypoint.waypoint.type === WaypointType.WAYPOINT_TYPE_DERIVED
      && isWithinLast30Days(waypoint.lastUpdated),
  );
  const sleepAppleHealth = validAppleHealthWaypoints?.filter(
    (waypoint, index) => waypoint.waypoint.name === 'sleepDerived' || waypoint.waypoint.name === 'sleepAppleHealth',
  )?.[0];
  const activityAppleHealth = validAppleHealthWaypoints?.filter(
    (waypoint, index) => waypoint.waypoint.name === 'activityDerived' || waypoint.waypoint.name === 'activityAppleHealth',
  )?.[0];
  const validNonAppleHealthWaypoints = waypoints?.filter(
    (waypoint, index) => waypoint.waypoint.type !== WaypointType.WAYPOINT_TYPE_DERIVED
      && isWithinLast30Days(waypoint.lastUpdated),
  );
  const fetchPatientWaypointSourcesResult = useQuery(
    [`${trackingUrls.waypointSources.queryUrl}`, providerId, id, sleepAppleHealth?.waypoint?.id],
    {
      queryFn: fetchWaypointSources(http.get),
      enabled: !!providerId && !!id && !!sleepAppleHealth?.waypoint?.id,
    },
  );
  const sourceOptions = React.useMemo(
    () => fetchPatientWaypointSourcesResult?.data?.sources?.map((s) => ({
      label: s.sourceName,
      value: s.sourceId,
      isSelected: s.isSelected,
      sourcePlatform: s.sourcePlatform,
    })) ?? [],
    [fetchPatientWaypointSourcesResult?.data?.sources],
  );

  const fetchPatientWaypointSourcesResultActivity = useQuery(
    [`${trackingUrls.waypointSources.queryUrl}`, providerId, id, activityAppleHealth?.waypoint?.id],
    {
      queryFn: fetchWaypointSources(http.get),
      enabled: !!providerId && !!id,
    },
  );
  const sourceOptionsActivity = React.useMemo(
    () => fetchPatientWaypointSourcesResultActivity?.data?.sources?.map((s) => ({
      label: s.sourceName,
      value: s.sourceId,
      isSelected: s.isSelected,
      sourcePlatform: s.sourcePlatform,
    })) ?? [],
    [fetchPatientWaypointSourcesResultActivity?.data?.sources],
  );
  useEffect(() => {
    setSourceId(sourceOptions?.[0]?.value);
    setSourceIdPlatformSleep(sourceOptions?.[0]?.sourcePlatform);
  }, [sourceOptions]);
  useEffect(() => {
    setSourceIdActivity(sourceOptionsActivity?.[0]?.value);
    setSourceIdPlatformActivity(sourceOptionsActivity?.[0]?.sourcePlatform);
  }, [sourceOptionsActivity]);

  const handleSourceChangeSleep = (sleepSourceId: string) => {
    setSourceId(sleepSourceId);
    setSourceIdPlatformSleep(sourceOptions.find((s) => s.value
     === sourceId)?.sourcePlatform ?? undefined);
  };
  const handleSourceChangeActivity = (activitySourceId: string) => {
    setSourceIdActivity(activitySourceId);
    setSourceIdPlatformSleep(sourceOptionsActivity.find((s) => s.value
    === sourceId)?.sourcePlatform ?? undefined);
  };
  const selfLogWaypoint = validNonAppleHealthWaypoints?.[0];
  const fetchSleepPatientWaypointTrackingResult = useQuery(
    [
      `${trackingUrls.waypointDetails.queryUrl}-${sourceId}-${TimeFrameOptions.LAST_30_DAYS}`,
      providerId,
      id,
      sleepAppleHealth?.waypoint?.id,
      TimeFrameOptions.LAST_30_DAYS,
      sourceId,
    ],
    {
      queryFn: fetchWaypointTracking(http.get, true),
      enabled: !!providerId && !!id && !!sourceId && !!sleepAppleHealth?.waypoint?.id,
    },
  );

  const fetchActivityPatientWaypointTrackingResult = useQuery(
    [
      `${trackingUrls.waypointDetails.queryUrl}`,
      providerId,
      id,
      activityAppleHealth?.waypoint?.id,
      TimeFrameOptions.LAST_30_DAYS,
      sourceIdActivity,
    ],
    {
      queryFn: fetchWaypointTracking(http.get),
      enabled: !!providerId && !!id && !!activityAppleHealth?.waypoint?.id,
    },
  );
  const fetchCurrentSelfReportWaypointTrackingResult = useQuery(
    [
      `${trackingUrls.waypointDetails.queryUrl}`,
      providerId,
      id,
      selfLogWaypoint?.waypoint?.id,
      TimeFrameOptions.LAST_30_DAYS,
    ],
    {
      queryFn: fetchWaypointTracking(http.get, false),
      enabled: !!providerId && !!id && !!selfLogWaypoint?.waypoint?.id,
    },
  );
  const fetchPreviousSelfReportWaypointTrackingResult = useQuery(
    [
      `${trackingUrls.waypointDetails.queryUrl}`,
      providerId,
      id,
      selfLogWaypoint?.waypoint?.id,
      TimeFrameOptions.LAST_30_DAYS,
      'previous',
    ],
    {
      queryFn: fetchWaypointTracking(http.get, false, true),
      enabled: !!providerId && !!id && !!selfLogWaypoint?.waypoint?.id,
    },
  );
  const isLoading = fetchActivityPatientWaypointTrackingResult.isLoading
    || fetchSleepPatientWaypointTrackingResult.isLoading
    || fetchCurrentSelfReportWaypointTrackingResult.isLoading
    || fetchPreviousSelfReportWaypointTrackingResult.isLoading
    || fetchPatientWaypointsResult.isLoading
    || fetchPatientWaypointSourcesResult.isLoading;
  const getWidget = (): 'sleep' | 'activity' | 'selfReport' | 'NO_DATA' | '' => {
    if (isLoading) {
      return '';
    }
    if (sleepAppleHealth) {
      return 'sleep';
    }
    else if (activityAppleHealth) {
      return 'activity';
    }
    else if (selfLogWaypoint) {
      return 'selfReport';
    }
    else {
      return 'NO_DATA';
    }
  };
  return {
    sourceOptions,
    setSourceId: handleSourceChangeSleep,
    fetchPatientWaypointsResult,
    fetchPatientWaypointSourcesResult,
    fetchSleepPatientWaypointTrackingResult,
    fetchActivityPatientWaypointTrackingResult,
    fetchCurrentSelfReportWaypointTrackingResult,
    fetchPreviousSelfReportWaypointTrackingResult,
    widget: getWidget(),
    sourceId,
    isLoading,
    sourceIdActivity,
    sourceOptionsActivity,
    setSourceIdActivity: handleSourceChangeActivity,
    sourceIdPlatformActivity,
    sourceIdPlatformSleep,
  };
};

export default usePatientTrackingSummary;
