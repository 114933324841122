/* eslint-disable react/no-array-index-key */
// @ts-ignore
import { useTheme } from '@emotion/react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import AppleHealthChartTooltip from 'pages/Dashboard/components/AppleHealthChartTooltip';
import ChartAxisLabel from 'pages/Dashboard/components/ChartAxisLabel';
import { SomaticScoreResponse, StroopTimeSeriesAPIResponse, WaypointTracking } from 'pages/Dashboard/types/waypoints.types';
import React, { useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, YAxis, Cell } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import Theme from 'theme';
import { getStartOfDayLocal } from 'utils/dateUtils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export enum DataKey {
  ActiveEnergyBurned = 'ActiveEnergyBurned',
  DaylightExposure = 'daylightExposure',
  Score = 'score',
  SomaticScore = 'somaticScore',
  Steps = 'steps',
  TotalCaloriesBurned = 'TotalCaloriesBurned'
}

type CustomTooltipProps = {
  isInDays: boolean;
  dataKey: string;
  dataLabel: string;
  units: string;
  label?: number;
  payload?: Payload<string | number | (string | number)[], string | number>[] | undefined;
  active?: boolean;
};

function CustomTooltip({
  active,
  payload,
  label,
  units,
  dataKey,
  dataLabel,
  isInDays,
}: CustomTooltipProps) {
  const data = [{
    key: dataKey,
    label: dataLabel,
    color: Theme.custom.colors.primaryMain,
    units,
    value: payload?.[0]?.payload?.[dataKey] ?? 0,
  }];

  return (
    <AppleHealthChartTooltip
      data={data}
      isInDays={isInDays}
      active={active}
      label={label}
      payload={payload}
    />
  );
}
interface AppleHealthActivitySummaryChartProps {
  chartData:
    | WaypointTracking[]
    | StroopTimeSeriesAPIResponse['data']
    | SomaticScoreResponse['data'];
  dataKey: DataKey;
  units: string;
  average?: {
    value: number;
    unit: string;
  };
  previousPeriodAverage?: {
    value: string;
    unit: string;
  };
  yAxisProps: {
    startTime: number;
    endTime: number;
    ticks: number[];
    isInDays: boolean;
  };
  yAxisDefinition: string;
  showAverages?: boolean;
  barSize: number;
  timeFrame?: string;
  barColor?: string;
  averageValue?: number;
  sourceOptions: { label: string; value: string; isSelected: boolean }[];
  setSourceId: (sourceId: string) => void;
  sourceId:string;
}

function AppleHealthActivitySummaryChart({
  chartData,
  dataKey,
  units,
  average,
  previousPeriodAverage,
  yAxisProps: { ticks, startTime, endTime, isInDays },
  yAxisDefinition,
  showAverages = true,
  barSize,
  timeFrame = '',
  barColor,
  averageValue,
  sourceOptions = [],
  setSourceId,
  sourceId,
}: AppleHealthActivitySummaryChartProps) {
  const [focusBar, setFocusBar] = useState(-1);

  const theme = useTheme();
  const data = chartData?.map((w) => {
    const timestamp = Number.isNaN(w as { timestamp: number })
      ? 0
      : (w as { timestamp: number })?.timestamp;
    const date = getStartOfDayLocal(timestamp * 1000, isInDays);
    let value = parseInt((w as WaypointTracking)?.absoluteValue as string, 10) || 0;

    if (dataKey === DataKey.Score) {
      value = (w as { score: number })?.score;
    }
    else if (dataKey === DataKey.SomaticScore) {
      value = Math.round(((w as { somaticScore: number })?.somaticScore ?? 0));
    }

    return {
      date,
      [dataKey]: value,
    };
  }) || [];

  const label = useMemo(() => {
    if (dataKey === DataKey.ActiveEnergyBurned) {
      return 'Active energy';
    }
    else if (dataKey === DataKey.DaylightExposure) {
      return 'Time';
    }
    else if (dataKey === DataKey.TotalCaloriesBurned) {
      return 'Total energy';
    }
    else {
      return 'Score';
    }
  }, [dataKey]);


  const yAxisProps = {} as {
    domain: number[];
    ticks: number[];
    interval: number;
  };
  if (dataKey === DataKey.Score) {
    let minScore = Infinity;
    let maxScore = 0;
    data.forEach((d) => {
      if (d.score < minScore) {
        minScore = d.score;
      }
      if (d.score > maxScore) {
        maxScore = d.score;
      }
    });

    minScore = minScore >= 2 ? minScore - 2 : minScore;
    maxScore += 2;
    const difference = maxScore - minScore;
    const steps = difference <= 12 ? 1 : 2;
    const count = Math.ceil((difference) / steps);
    yAxisProps.domain = [minScore, maxScore];
    yAxisProps.interval = 0;
    yAxisProps.ticks = Array.from({ length: count }, (_, i) => minScore + i * steps);
  }

  const priorAvgText = useMemo(() => `prior ${timeFrame.replace(' ', '-').replace('s', '')} avg.`, [timeFrame]);
  const isUp = useMemo(() => {
    const currentAverage = Math.round(average?.value ?? 0);
    const previousPeriodAverageValue = Math.round(+(previousPeriodAverage?.value ?? ''));
    return currentAverage
      && previousPeriodAverageValue
      && currentAverage !== previousPeriodAverageValue
      ? currentAverage > previousPeriodAverageValue
      : undefined;
  }, [average, previousPeriodAverage]);
  const ArrowIcon = isUp
    ? ArrowDropUpIcon
    : ArrowDropDownIcon;

  const previousPeriodValue = React.useMemo(() => Math.round(+(previousPeriodAverage?.value ?? '')), [previousPeriodAverage]);
  const handleChange = (event: SelectChangeEvent) => {
    setSourceId(event.target.value as string);
  };

  return (
    <>
      <Grid container mb={1}>
        {sourceOptions.length > 1 && (
        <FormControl sx={{ mb: 3, flexGrow: 1 }}>
          <InputLabel>Data source</InputLabel>
          <Select value={sourceId} label='Data source' onChange={handleChange} className='fs-mask'>
            {sourceOptions.map((s) => (
              <MenuItem key={s.value} value={s.value} className='fs-mask'>
                {s.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        )}
        {showAverages && (
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='700' color='primary'>
              {label}
            </Typography>
            <Box display='flex' alignItems='center' mb={2} height={20}>
              {Math.round(average?.value ?? 0) > 0 ? (
                <Box display='flex' alignItems='flex-end'>
                  <Typography variant='h6' lineHeight='100%' mr={0.5}>
                    {Math.round(average?.value ?? 0)}
                  </Typography>
                  <Typography lineHeight='100%' color={Theme.custom.colors.lightTextSecondary}>{average?.unit?.toLowerCase()}</Typography>
                </Box>
              ) : '-'}
              {isUp !== undefined && <ArrowIcon color='action' />}
            </Box>
            <Typography fontSize={12} color={Theme.custom.colors.lightTextSecondary} mb={0.5}>
              {priorAvgText}
            </Typography>
            <Typography display='flex' alignItems='baseline'>
              <Typography lineHeight='100%' mr={0.5} fontWeight={400}>
                {previousPeriodValue || '-'}
              </Typography>
              {previousPeriodValue > 0 && <Typography variant='body2' lineHeight='100%' color={Theme.custom.colors.lightTextSecondary}>{average?.unit?.toLowerCase()}</Typography>}
            </Typography>
          </Grid>
        )}
        {averageValue && !isNaN(averageValue) && (
        <Grid item xs={12} mb={1}>
          Average:
          {' '}
          {Math.round(averageValue)}
        </Grid>
        )}
        <Grid item xs={12} marginTop={showAverages ? 0 : 8}>
          {yAxisDefinition}
        </Grid>
      </Grid>
      <Box marginTop='0'>
        <ResponsiveContainer width='100%' height={260}>
          <BarChart
            barSize={barSize}
            data={data}
            onMouseMove={(state) => {
              setFocusBar(state?.isTooltipActive ? state?.activeTooltipIndex ?? -1 : -1);
            }}
            onMouseLeave={() => {
              setFocusBar(-1);
            }}
            margin={{ top: 0, left: 0, right: 5, bottom: 0 }}
          >
            <XAxis
              dataKey='date'
              type='number'
              domain={[startTime, endTime]}
              tick={<ChartAxisLabel isInDays={isInDays} />}
              tickFormatter={() => ''}
              ticks={ticks}
              height={25}
              tickLine={false}
              tickSize={2}
            />
            <YAxis
              width={30}
              tick={{ dy: 2 }}
              orientation='left'
              tickFormatter={(v) => `${v} ${units.toLowerCase()}`}
              fontSize={10}
              tickCount={6}
              tickLine={false}
              tickSize={2}
              {...yAxisProps}
            />
            <CartesianGrid />
            <Tooltip
              content={(
                <CustomTooltip
                  units={units}
                  dataKey={dataKey}
                  dataLabel={label}
                  isInDays={isInDays}
                />
              )}
              cursor={false}
            />
            <Bar dataKey={dataKey} fill={barColor ?? theme.custom.colors.primaryMain}>
              {data.map((entry, index) => (
                <React.Fragment key={`${entry?.id}`}>
                  <Cell

                  // eslint-disable-next-line react/jsx-props-no-multi-spaces
                    key={`${entry?.id}`}
                    fill={
                    focusBar !== index
                      ? barColor ?? theme.custom.colors.primaryMain
                      : `${barColor ?? theme.custom.colors.primaryMain}aa`
                  }
                  />
                </React.Fragment>
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}

export default AppleHealthActivitySummaryChart;
