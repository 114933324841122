import { addDays, format, parse, subYears } from 'date-fns';


const d = new Date();

export type GoogleDate = {
  year: number;
  month: number;
  day: number;
};

export type Time = {
  hours: number;
  minutes: number;
  seconds: number;
  nanos: number;
}

export type GoogleTimestamp = {
  nanos: number;
  seconds: number;
}

export const browserDateFormat = 'yyyy-MM-dd';


export enum Frequency {
  FREQUENCY_UNSPECIFIED = 'FREQUENCY_UNSPECIFIED',
  FREQUENCY_NA = 'FREQUENCY_NA',
  FREQUENCY_HOURLY = 'FREQUENCY_HOURLY',
  FREQUENCY_DAILY = 'FREQUENCY_DAILY',
  FREQUENCY_WEEKLY = 'FREQUENCY_WEEKLY',
  FREQUENCY_MONTHLY = 'FREQUENCY_MONTHLY',
  FREQUENCY_YEARLY = 'FREQUENCY_YEARLY'
}

export const formattedDates = {
  today: format(d, browserDateFormat),
  age18: format(subYears(d, 18), browserDateFormat),
};

export const formatToBrowserDate = (date: Date) => format(date, browserDateFormat);

export const dateStringToGoogleDate = (dateString: string): GoogleDate => {
  const parsedDate = parse(dateString, browserDateFormat, new Date());
  return {
    year: parsedDate.getFullYear(),
    month: parsedDate.getMonth() + 1,
    day: parsedDate.getDate(),
  };
};

export function googleDateToDateString(googleDate: GoogleDate | null, timestamp?: boolean) {
  if (!googleDate) {
    return 'Unknown date';
  }
  const { day, month, year } = googleDate;
  const formatString = `${year}-${month}-${day}`;
  const parsedDateFns = parse(formatString, browserDateFormat, new Date());
  return timestamp ? parsedDateFns.getTime() : format(parsedDateFns, 'M/d/yyyy');
}

export function formatRelativeDate(inputDate: Date): string {
  const now = new Date();
  const date = new Date(inputDate);

  const diffTime = now.getTime() - date.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffDays / 30);

  if (diffDays === 0) {
    return 'Today';
  }
  else if (diffDays === 1) {
    return 'Yesterday';
  }
  else if (diffDays < 7) {
    return `${diffDays} days ago`;
  }
  else if (diffDays < 30) {
    return diffWeeks === 1 ? '1 week ago' : `${diffWeeks} weeks ago`;
  }
  else {
    return diffMonths === 1 ? '1 month ago' : `${diffMonths} months ago`;
  }
}

export function googleTimestampToDateString(googleTimestamp: GoogleTimestamp | string) {
  if (!googleTimestamp) {
    return '';
  }

  if (typeof googleTimestamp === 'string') {
    return googleDateToDateString(dateTimeStringToGoogleDate(googleTimestamp));
  }

  const date = new Date(googleTimestamp.seconds * 1000);
  return format(date, 'M/d/yyyy');
}

export const frequencyFormatter = (frequency: Frequency) => {
  switch (frequency) {
    case Frequency.FREQUENCY_DAILY:
      return 'per day';
    case Frequency.FREQUENCY_HOURLY:
      return 'per hour';
    case Frequency.FREQUENCY_MONTHLY:
      return 'per month';
    case Frequency.FREQUENCY_WEEKLY:
      return 'per week';
    case Frequency.FREQUENCY_YEARLY:
      return 'per year';
    case Frequency.FREQUENCY_UNSPECIFIED:
      return '';
    case Frequency.FREQUENCY_NA:
      return '';
    default:
      return '';
  }
};

export const dateTimeStringToGoogleDate = (
  dateString: string,
): GoogleDate => {
  const date = dateString.split('T');
  const [year, month, day] = date[0].split('-');

  return {
    year: +year,
    month: +month,
    day: +day,
  };
};

export const subtractDaysFromDate = (date: number, days: number): number => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const subtractedDate = new Date(date - days * millisecondsPerDay);
  return subtractedDate.getTime();
};

export const getHoursFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.getHours(); // Use getUTCHours() if working with UTC time
};

// Function to calculate the difference in hours
export const findNearestHourFromTop = (timestamp: number) => {
  // Get the current hour
  const currentHour = getHoursFromTimestamp(timestamp);
  // Calculate the difference between the current hour and the nearest top hour
  const differenceToTopHour = 1 - (currentHour % 1);
  // Calculate the difference between the current hour and the nearest bottom hour
  const nearestTopHour = currentHour + differenceToTopHour;
  // Return the nearest top hours as an object
  return nearestTopHour;
};

// Function to find the nearest hour from the bottom
export const findNearestHourFromBottom = (timestamp: number) => {
  // Get the current hour
  const currentHour = getHoursFromTimestamp(timestamp);
  // Calculate the difference between the current hour and the nearest bottom hour
  const differenceToBottomHour = currentHour % 1;
  // Calculate the nearest bottom hour
  const nearestBottomHour = currentHour - differenceToBottomHour;
  // Return the nearest bottom hour
  return nearestBottomHour;
};

// Function to calculate the difference in hours
export const getHourDifference = (timestamp1: number, timestamp2: number) => {
  // Extract hours from both timestamps
  const hours1 = findNearestHourFromBottom(timestamp1);
  const hours2 = findNearestHourFromTop(timestamp2);

  // Calculate the difference in hours
  const differenceInHours = hours1 - hours2 > 0 ? 24 - hours1 + hours2 : hours1 - hours2;

  // Return the difference as an integer
  return Math.abs(differenceInHours);
};

export function getStartOfDayLocal(timestamp: number, isInDays = true): number {
  // Create a Date object from the GMT timestamp
  const gmtDate = new Date(timestamp);

  // Extract the local year, month, and day
  const localYear = gmtDate.getFullYear();
  const localMonth = gmtDate.getMonth(); // 0-indexed
  const localDay = gmtDate.getDate();

  // Create a new Date object for the start of the day in local time
  const localStartOfDay = new Date(localYear, localMonth, localDay, 0, 0, 0);

  // Return the timestamp for the start of the local day
  return isInDays
    ? localStartOfDay.getTime()
    : addDays(timestamp, new Date().getDate() - 2).getTime();
}

function googleDateToDate(googleDate: GoogleDate): Date {
  const { year, month, day } = googleDate;
  return new Date(year, month - 1, day);
}

export function formatTimeDifference(date: Date | GoogleDate): string {
  const inputDate = date instanceof Date ? date : googleDateToDate(date);
  const now = new Date();
  const diffInMilliseconds = now.getTime() - inputDate.getTime();
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    return 'Today';
  }
  if (diffInDays === 1) {
    return 'Yesterday';
  }
  if (diffInDays > 1 && diffInDays <= 59) {
    return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  }
  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths <= 11) {
    return `${diffInMonths} month${diffInMonths === 1 ? '' : 's'} ago`;
  }
  const diffInYears = Math.floor(diffInMonths / 12);
  return `${diffInYears} year${diffInYears === 1 ? '' : 's'} ago`;
}


export const calculateDaysDifference = (date1: GoogleDate, date2: Date): number => {
  const d1 = new Date(date1?.year ?? 0, (date1?.month ?? 1) - 1, date1?.day ?? 1);
  const timeDiff = Math.abs(d1.getTime() - date2.getTime());
  return Math.floor(timeDiff / (1000 * 3600 * 24));
};

export const formatEventDate = (refreshedOn: GoogleDate, currentDate: Date) => {
  const daysDiff = calculateDaysDifference(refreshedOn, currentDate);
  if (daysDiff <= 59) {
    return daysDiff === 1 ? '1 day ago' : `${daysDiff} days ago`;
  }
  else {
    const eventDate = new Date(refreshedOn.year, refreshedOn.month - 1, refreshedOn.day);
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: eventDate.getFullYear() === currentDate.getFullYear() ? undefined : 'numeric',
      month: 'short',
      day: 'numeric',
    });

    return formatter.format(eventDate);
  }
};

export function formatGoogleDate(googleDate: GoogleDate, showYear?: boolean): string {
  const { year, month, day } = googleDate;
  const date = new Date(year, month - 1, day);

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  };
  if (showYear) {
    options.year = 'numeric';
  }
  return date.toLocaleString('en-US', options);
}

/**
 * Formats a given time in seconds into a human-readable string.
 *
 * @param time - The time in seconds to format.
 * @param numberFormat - Optional boolean to determine if the output should be in number format.
 *
 * @returns An object containing:
 * - `time`: The formatted time string.
 * - `unit`: The unit of time (e.g., 'HR', 'MIN', 'SEC', 'hrs', 'mins', 'secs').
 * - `sec`: The original time in seconds.
 */
export const formatTime = (time: number, numberFormat = false) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;
  let formattedTime = numberFormat ? `${seconds} sec${seconds > 1 ? 's' : ''}` : `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  if (numberFormat) {
    if (hours > 0) {
      formattedTime = `${hours} hr${hours !== 1 ? 's' : ''}`;
      if (minutes > 0) {
        formattedTime += ` ${minutes} min${minutes !== 1 ? 's' : ''}`;
      }
      if (seconds > 0) {
        formattedTime += ` ${seconds} sec${seconds !== 1 ? 's' : ''}`;
      }
    }
    else if (minutes > 0) {
      formattedTime = `${minutes} min${minutes !== 1 ? 's' : ''}`;
      if (seconds > 0) {
        formattedTime += ` ${seconds} sec${seconds !== 1 ? 's' : ''}`;
      }
    }
  }
  else if (hours > 0) {
    formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
  else if (minutes > 0) {
    formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  return {
    time: formattedTime,
    sec: time,
  };
};
